@font-face {
  font-family: 'museo-sans-rounded-2';
  src: url('./Assets/useanyfont/190130083504Museo-Sans-Rounded-2.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-family: 'museo-sans-rounded-3';
  src: url('./Assets/useanyfont/190130083358Museo-Sans-Rounded-3.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-family: museo-sans-rounded-100;
  src: url("./Assets/Font/museosansrounded/museosansrounded-100-webfont.ttf");
}

@font-face {
  font-family: museo-sans-rounded-300;
  src: url("./Assets/Font/museo-sans-rounded-300.ttf");
}

@font-face {

  font-family: museo-sans-rounded-500;
  src: url("./Assets/Font/museosansrounded/museosansrounded-500-webfont.ttf");
}

@font-face {
  font-family: museo-sans-rounded-700;
  src: url("./Assets/Font/museo-sans-rounded-700.ttf"),
    url("./Assets/Font/museo-sans-rounded-700.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: museo-sans-rounded-900;
  src: url("./Assets/Font/museosansrounded/museosansrounded-900-webfont.ttf");
}


body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
li,
a {
  font-family: 'museo-sans-rounded-2' !important;
}

 html,
 body,
 #root {
  height: 100%;
}

.rootscroll{
  overflow-y: scroll !important;
}



#root{
      background-color: rgb(62, 121, 141);
}

:root {
  --bg-main: #3c8a9e;
  --dimgray: dimgray;
  /* --fontcolor:#35383a; */
}

.navbar a {
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  color: #333333;
}

.form-control {
  color: lightgray !important;
  font-family: museo-sans-rounded-300;
  font-size: 1rem;
  display: inline-block !important;
  word-break: keep-all;
}
/* --------------------------------------------------------------------------------------------------------------- */
.accordion-header h5{
  color: var(--dimgray) ;
}

.answer,
#questionsection .questionclass li {
  font-size: 16px;
  font-weight: 500;
  color: var(--dimgray) ;
}

.helpdetails .nav-link,
.managedetails .nav-link {
  font-size: 13px;
}


#ai-header .nav-link {
  color: white;
}

.bg-main {
  background-color: var(--bg-main) !important;
}

#questionsection .accordion .accordion-body,
#questionsection .accordion-button:not(.collapsed) {
  background-color: white;
  margin-top: 5px;
  border-radius: 5px;
}

#questionsection .accordion-button:focus {
  box-shadow: none;
}

#questionsection .accordion-item {
  background-color: transparent;
}



#questionsection .questionclass {
  display: grid;
  grid-template-columns: auto auto;
  list-style-type: lower-alpha;
  padding-left: 19px;
  row-gap: 10px;
}

.logo {
  max-height: 50px;
  width: auto;
}

#questionsection .accordion-button::after {
  margin-left: 20%;
}

#questionsection .buttonstyle {
  width: 18%;
  border-radius: 5px;
  background-color: rgb(115, 204, 209);
  box-shadow: rgb(55, 174, 181) 0px 3px 0px 0px;
  cursor: pointer;
  border: none;
  height: 35px;
  margin-bottom: 7px;
  color: white;
  opacity: 0.5;
}

.enalbeclass {
  width: 18%;
  border-radius: 5px;
  background-color: rgb(115, 204, 209) !important;
  box-shadow: rgb(55, 174, 181) 0px 3px 0px 0px !important;
  opacity: 1 !important;
  cursor: pointer;
  border: none !important;
  height: 35px;
  margin-bottom: 7px;
  color: white;
}

#ai-header .managedetails {
  display: none;
  position: absolute;
  border: 0;
  border-radius: 0px;
  /* min-width: 15rem; */
  right: -50px;
  left: auto;
  top: 62px;
}

#ai-header .helpdetails {
  display: none;
  position: absolute;
  border: 0;
  border-radius: 0px;
  min-width: 10rem !important;
  left: -17px;
  top: 42px;
}

.managedetails .list-group-item {
  border: 0;
}

.manageaccount:hover .managedetails {
  display: block !important;
}

.help:hover .helpdetails {
  display: block !important;
}

.helpdetails {
  min-width: unset !important;
}



.border-animate::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: white;
  transition: width .3s;
}

.border-animate:hover::after {
  width: 100%;
}

.list-group-item {
  padding: 8px 25px !important;
}

.list-group-item:first-child {
  padding-top: 20px !important;
}

.list-group-item:last-child {
  padding-bottom: 20px !important;
}

.helpdetails .nav-link,
.managedetails .nav-link {
  color: black !important;
}

#questionsection .ai-inputbox,
#questionsection .ai-inputbox::placeholder {
  font-size: 18px;
  font-style: italic;
  color: #5c5c5c !important;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  /* transform: translate(-50%,-50%); */
  z-index: 999;
}


.accordion-button{
  justify-content: space-between;
}

.createdisable{
  opacity: 0.5;
}

.createenable{
  opacity:1 !important;
}